
        /* 
       Reset
       http://meyerweb.com/eric/tools/css/reset/ 
       v2.0 | 20110126
       License: none (public domain)
    */

    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        background-color: #fff;
    }

    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure, 
    footer, header, hgroup, menu, nav, section {
        display: block;
    }

    ol, ul {
        list-style: none;
    }

    blockquote, q {
        quotes: none;
    }

    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    /* -------- Main Styles --------- */


    /* General Tags */

    h1, h2, h3 {
        margin-bottom: 12px;
        color: #236BB2;
    }

    h1 {
        font-size: 200%;
    }

    h2 {
        font-size: 140%;
    }

    h3 {
        font-size: 110%;
    }

    p {
        margin-bottom: 25px
    }

    a:link, a:visited {
        color: darkblue;
    }

    a:hover, a:active {
        color: darkblue;
    }

    html
    {
        background: #fff;
        height: 100%;
    }

    body
    {
        height: 100%;
    }

    /* Container */

    #container
    {
        text-align: left;
        width: 960px;
        margin: 0px auto -100px;
        padding-top: 0px;
        padding-bottom: 0px;
        min-height: 100%;
        height: auto !important;
        height: 100%;

        img
        {
        	width: 400px;
        	height: 197px;
        	margin-left: 280px;
        	margin-bottom: 100px;
        }

        h1
        {
        	font-family: 'HelveticaNeueW01-UltLt';
        	color: #000;
        	width: 960px;
        	text-align: center;
        	margin-bottom: 60px;

        	a
        	{
        		font-size: 1em;
        	}
        }

        a
        {
			font-family: 'HelveticaNeueW01-UltLt';
			font-weight: normal;
			text-decoration: none;
        	font-size: 1.5em;
        	color: #000;
        	width: 960px;
        	text-align: center;
        	margin-top: 100px;
        }

        p
        {
			font-family: 'HelveticaNeueW01-UltLt';
			font-weight: normal;
			text-decoration: none;
        	font-size: 1.5em;
        	color: #000;
        	width: 960px;
        	text-align: center;
        }
    }

	.standard_box
	{
		width: 960px;
		height: 200px;
		margin: 0px auto 0px;
		padding-top: 0px;
		padding-bottom: 0px;

		background: #fff;
    }

    /* Header */

    #header
    {
        float:left;
        padding:0px;
        margin: 0px;
        height: 64px;
        min-width:100%;
    }

    #headercontent
    {
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 21px;
        padding-right: 21px;
        margin: 0px auto 0px auto;
        width: 600px;
        height: 64px;
        /*background: url(/img/trip_cubes_logo.png) no-repeat;*/

    }

    /*
    @media only screen and (-Webkit-min-device-pixel-ratio: 1.5),
    only screen and (-moz-min-device-pixel-ratio: 1.5),
    only screen and (-o-min-device-pixel-ratio: 3/2),
    only screen and (min-device-pixel-ratio: 1.5)
    {
      #headercontent
      {
        background: url(/img/trip_cubes_logo@2x.png) no-repeat;
        background-size: 340px 64px;
      }
    }

    */


    /* Footer */

    #push
    {
        height: 100px;
    }

    #footer
    {
        background-color: #fff;
        color: #b1b1b1;
        padding:0px;
        margin: 0px;
        height: 100px;
        min-width:100%;

    }

    #footercontent
    {
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 21px;
        padding-right: 21px;
        margin: 0px auto 0px auto;
        width: 600px;
        height: 64px;

    }


    /* Searchbar */

    #searchbar {
        float: right;
    }
    /* Nav */

    #nav
    {
        font-family: "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: 200;
        font-size: 15px;
        padding-top: 8px;
        position: relative;
        overflow: hidden;
        float: right;
    }

    #nav ul
    {
        margin: 0;
        padding: 0;
    }

    #nav ul li
    {
        display: inline;
    }

    #nav li a
    {
        float: left;
        display: block;
        text-decoration: none;
        padding: 12px 8px;
        color: #fff;
    }

    #nav li a:hover
    {
        color: #F99B0C;
    }

    /* Content */

    #content
    {
        clear: both;
        padding: 0px;
        padding-top: 100px;
        overflow: hidden;
    }

    #content ul {
        list-style-type: square;
        margin-left: 20px;
    }

    #content li {
        margin-bottom: 5px;
    }

    /* Flash Message */
    #message {
        background-color: #FFF ;
        border: 1px solid #555;
        color: #000;
        padding: 10px;
    }

    /* Form Errors */

    #form-errors {
        margin-bottom: 20px;
        margin-left: 30px;
        list-style-type: square;
    }

    /* Form Input Styles */

    #question, #username, #password, #password_confirmation, #answer {
        width: 250px;
    }

    #form-errors li {
        color: red;
    }

    /* Ask div */

    #ask {
        margin-bottom: 30px;
        border-bottom: 1px solid #fff;
    }

    /* Answers div */

    #answers {
        border-top: 1px solid #ccc;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    /* post-answer div */

    #post-answer {
        border-top: 1px solid #ccc;
        padding-top: 20px;
    }

    /* Misc. Class Styles */

    .pagination {
        margin-top: 20px;
    }

    .pagination ul li {
        margin: 0 2px;
        display: inline;
    }